<template>
  <div :style="{'height':baseDivHeight,'margin-top':'1px'}">
      <div class="column" :style="{'width': mainDivWidth,'height':mainDivHeight,'border': '1px solid black'}">
          <div class="column" :style="{'width': workAreaWidth,'height':workAreaHeight,'border': '1px solid black'}">
              <div class="row" id="chalArea" :style="{'height':chalHeight,'overflow': 'hidden'}">
                  <div class="column" id="challenge_content" :style="{'text-align':chalAlignment,'width':chalWidth}">
                  </div>
                  <div class="column" :style="{'text-align':chalAlignment,'width':btnWidth,'display':'flex','flex-direction':'column'}">
                    <v-icon id="toggleChal" @click="toggleChalHeight">mdi-arrow-expand-all</v-icon>
                    <v-icon @click="increaseChalHeight" >mdi-plus-circle</v-icon>
                    <v-icon @click="decreaseChalHeight">mdi-minus-circle</v-icon>
                  </div>
              </div>
              <div class="row" :style="{'height':chatAreaHeight,'border':'1px solid black'}">
                  <div  class="scroll">
                    <canvas id="canvas" width="600" height="400" top="10px">
                    </canvas> 
                    <!--<canvas id="canvas" width="300" height="300"></canvas>-->
                  </div> 
              </div>
              <div class="row" :style="{'height':inputAreaHeight,'border':'1px solid black'}">
                <div class="column" style="width:70%; ">
                  <div class="row" style="width:100%;">
                    <v-text-field
                      id="inputPreviewMessage"
                      label="Preview Area"
                      v-model="previewIinkMessage"
                      outlined
                      dense
                      disabled
                    ></v-text-field>
                  </div>
                  <hr> 
                  <div v-show="canvasOpen" id="userInputCanvas" class="row" style="height:142px;width:100%;">
                    <canvas id="inputCanvasArea" width="620" height="140" style="border:1px solid blue">
                    </canvas>
                  </div> 
                  <div v-show="textAreaOpen" class="row" style="height:2%;width:100%;" >
                    <v-textarea id="inputMessage" label="text Area" v-model="userChatMessage"  outlined ></v-textarea>
                  </div> 
                </div>
                <div class="column" style="width:30%;">
                  <v-btn id="inputSendMessage" @click="sendMessage">Send</v-btn>
                  <br>
                  <label for="chkYes">
                      <input type="radio" id="chkYes" name="chkPassPort" @click="ShowCanvas()" />
                      Canvas
                  </label>
                  <label for="chkNo">
                      <input type="radio" id="chkNo" name="chkPassPort" @click="ShowTextArea()" />
                      Text
                  </label>
                  <br>
                  <v-btn @click="formatInputField">clear</v-btn>
                </div>
              </div>
          </div>
          <div class="column" :style="{'width': commandPaletteWidth,'height':commandPaletteHeight,'border': '1px solid black'}">
              <div class="row" :style="{'height':editorAreaHeight,'border': '1px solid black'}">     
                  <v-select
                      v-model="editorName"
                      :items="editorItems"
                      id="inputSelectEditors"
                      label="Editors"
                      outlined
                      @click="editorName=''"
                  ></v-select>
                  <!--<button id="undo" class="nav-btn btn-fab-mini btn-lightBlue" disabled>
                  </button>
                  <button id="redo" class="nav-btn btn-fab-mini btn-lightBlue" disabled>
                  </button>
                  <div>
                    <button class="classic-btn" id="convert" disabled>Convert</button>
                    <button class="classic-btn" id="convert" disabled>Convert</button>
                  </div>
                  <div id="editor2" touch-action="none"></div>-->
                  <v-btn id="inputleaveRoom" @click="leaveRoomFn">leaveRoom</v-btn>   
              </div>
              <div class="row" :style="{'height':personalViewPortAreaHeight,'border': '1px solid black'}">
                  <h4>Personal-view port</h4>
              </div>
              <div class="row" :style="{'height':presentationViewPortAreaHeight,'border': '1px solid black'}">
                  <h4 >presentation view-port</h4>
                  <div id="meet"></div>
              </div>
          </div>
      </div>  
 </div>
 
</template>
<script>
import {fabric} from "fabric";
//import iink from 'iink-js'
import styleConfig from '../assets/config' 
import katex from 'katex';
import rcApi from '../api/index.js';
import axios from 'axios';
import AppConfig from '../../config.js';
//import ImageUpload from './ImageUpload.vue';
//import kaTexEditor from './kaTexEditor.vue';
//import RichTextEditor from './RichTextEditor.vue';
let canvas=null
let inputAreaCanvas=null//todo
export default {
  data() {
      return {
          KC_baseURL: AppConfig.KC_Base_URL,
          UI_baseURL: AppConfig.UI_Base_URL,
          work_api_Base_URL: AppConfig.Work_API_Base_URL,
          challenge_api_Base_URL: AppConfig.Challenge_API_Base_URL,
          Auth_API_Base_URL: AppConfig.Auth_API_Base_URL,
          tutorIsNotHere: true,
          drawer: true,
          items: [
              { title: "Home", icon: "mdi-home-city" },
              { title: "My Account", icon: "mdi-account" },
              { title: "Users", icon: "mdi-account-group-outline" },
          ],
          mini: true,
          slotsList: [],
          proposer_name: "",
          listMyChallenges: [],
          editorName:"",
          editorType: "Text",
          editorItems: ["KaTeX", "Image", "Rich Text", "Diagrams"],
          mediaChatApi: null,
          mediaAudioApi:null,
          webSocketUrl: 'wss://qroc.rasree.com/websocket',
          connectedToApi: true,
          loggedIn: false,
          userId: '',
          authToken: '',
          username: "",
          password: "",
          roomName: 'sandbox',
          roomId: '',
          roomConnected: false,
          userInputMessage: '',
          userChatMessage:'',
          previewIinkMessage:'',
          inputChatMessage:'',
          messages: [],
          errors: [],
          roomsInfo: [],
          chatMsg:[],
          candidatesList:{},
          new_room_name: "",//TODO
          createRoomWindow:false,
          openConnectRoomWindow:false,
          openSendMsgWindow:false,
          getRooms:"",
          rendermessages:"",
          previewFormula: "",
          slotPartId:"",
          userJwtToken:"",
          challengeDetails:"",
          chalID:"",
          chalWidth:styleConfig.chalWidth,
          btnWidth:styleConfig.btnWidth,
          //displayName:"flex",
          //flexdirection: "column-reverse",
          baseDivHeight:styleConfig.baseDivHeight,
          mainDivHeight:styleConfig.mainDivHeight,
          mainDivWidth:styleConfig.mainDivHeight,
          workAreaWidth:styleConfig.workAreaWidth,
          workAreaHeight:styleConfig.workAreaHeight,
          chalHeight:styleConfig.chalHeight,
          chalAlignment:styleConfig.chalAlignment,
          chatAreaHeight:styleConfig.chatAreaHeight,
          inputAreaHeight:styleConfig.inputAreaHeight,
          inputTextAreaHeight:styleConfig.inputTextAreaHeight,
          inputTextPreviewAreaHeight:styleConfig.inputTextPreviewAreaHeight,
          inputTextPreviewAreaWidth:styleConfig.inputTextPreviewAreaWidth,
          inputTextAreaWidth:styleConfig.inputTextAreaWidth,
          inputSendBtnWidth:styleConfig.inputSendBtnWidth,
          commandPaletteWidth:styleConfig.commandPaletteWidth,
          commandPaletteHeight:styleConfig.commandPaletteHeight,
          editorAreaHeight:styleConfig.editorAreaHeight,
          personalViewPortAreaHeight:styleConfig.personalViewPortAreaHeight,
          presentationViewPortAreaHeight:styleConfig.presentationViewPortAreaHeight,
          chatMessage:"",
          canvasOpen:true,
          textAreaOpen:false,
          textelement:[],
          newtext:[],
          canvasHeightLineLoc:0,
          inputCanvasDrawingMode:true,
          x_cor:0,
          y_cor:0,
          currentTime: 0,
          drawingCoordinatesList: [],
          mouseDownValue:false,
          //canvas:null,
      };
  },
  //components: { RichTextEditor, ImageUpload, kaTexEditor },
  async mounted() {
    canvas = new fabric.Canvas('canvas');
    inputAreaCanvas = new fabric.Canvas('inputCanvasArea',{
        isDrawingMode:this.inputCanvasDrawingMode
      });
      inputAreaCanvas.on('mouse:up', this.mouseUpEvent)
        console.log('Event mouse:up Triggered')

      inputAreaCanvas.on('mouse:down', this.mouseDownEvent)
        console.log('Event mouse:down Triggered');

      inputAreaCanvas.on('after:render', function () {
        console.log('Event after:render Triggered');
      });

      inputAreaCanvas.on('mouse:move', this.getCoordinates)
        console.log('Event object:moving Triggered')

      inputAreaCanvas.on('object:modified', function () {
        console.log('Event object:modified Triggered');
      });

      inputAreaCanvas.on('path:created', this.addTimeStampFN)
        console.log('Event object:modified Triggered')
      /*let mousePointer = canvas.getPointer(event.e)
      if(this.mouseDownValue && this.isDrawingMode){
        this.x_cor = mousePointer.x
        this.y_cor = mousePointer.y
        const newDate = new Date();
        this.currentTime = newDate.getTime();
        //console.log("(x,y,ts) : ", this.x_cor, ",", this.y_cor, ",", this.currentTime)
        this.drawingCoordinatesList.push([this.x_cor, this.y_cor, this.currentTime])
      }
      canvas.on("path:created", this.addTimeStampFN);
    /*const editorELement1 = document.getElementById('editor');
    const undoElement = document.getElementById('undo');
    const redoElement = document.getElementById('redo');
    const convertElement = document.getElementById('convert');
    //const clearElement = document.getElementById('clear');

  let toImport = null;

  editorELement1.addEventListener('exported', (event) => {
    const exports = event.detail.exports;
    if(exports && exports['application/vnd.myscript.jiix']) {
      toImport = exports['application/vnd.myscript.jiix'];
    }
  });

  convertElement.addEventListener('click', () => {
    editorELement1.editor.convert();
  });

  [editorELement1].forEach((editor) => {
    editor.addEventListener('changed', (event) => {
      undoElement.disabled = !event.detail.canUndo;
      redoElement.disabled = !event.detail.canRedo;
      convertElement.disabled = event.detail.isEmpty;
    });

    undoElement.addEventListener('click', () => {
      registeredEditor.undo();
    });
    redoElement.addEventListener('click', () => {
      registeredEditor.redo();
    });

    window.addEventListener('resize', () => {
      registeredEditor.resize();
    });

    const registeredEditor = iink.register(editor, {
      recognitionParams: {
        type: 'MATH',
        protocol: 'WEBSOCKET',
        server: {
          scheme: 'https',
          host: 'webdemoapi.myscript.com',
          applicationKey: 'f1355ec8-c74a-4da9-8d63-691ab05952eb',
          hmacKey: '752acf37-5a45-481b-9361-fcb32cd7f6a1'
        },
        iink: {
          math: {
            mimeTypes: ['application/vnd.myscript.jiix', 'application/x-latex']
          },
          export: {
            jiix: {
              strokes: true,
            }
          }
        }
      }
    });
    editor.addEventListener('exported', (event) => {
      this.editorType = "digital-ink"
        console.log("event listner", JSON.parse(event.detail.exports["application/vnd.myscript.jiix"]));
        console.log("editor is", registeredEditor);//Main thing
        let iinkResp = JSON.parse(event.detail.exports["application/vnd.myscript.jiix"])
        this.previewIinkMessage = iinkResp.expressions[0].label
        this.userInputMessage = this.previewIinkMessage
        console.log("iink message-",this.previewIinkMessage);
        //this.sendMessage()
      });
    })*/
    this._keyListener = function(e) {
      //console.log("event", e);
          if (e.altKey){
            if(e.key === 'a'){
              e.preventDefault();
              this.toggleChalHeight();
            }
            if(e.key === 'm'){
              e.preventDefault();
              this.increaseChalHeight();
            }
            if(e.key === 'l'){
              e.preventDefault();
              this.decreaseChalHeight();
            }
            if(e.key === 'p'){
              e.preventDefault();
              this.ShowCanvas();
            }
            if(e.key === 't'){
              e.preventDefault();
              this.ShowTextArea();
            }
          }
      };
    document.addEventListener('keydown', this._keyListener.bind(this));
    this.mediaChatApi = rcApi.connectToRocketChat (this.webSocketUrl)
    console.log("--",this.mediaChatApi);
    this.mediaChatApi.onError (error => this.errors.push (error))
    this.mediaChatApi.onCompletion (resp => console.log ("finished", resp))
    this.mediaChatApi.onMessage (message => {
      console.log("--msg recieved--", message)
      this.messages.push (message)
      if(message.msg == 'ping'){
          console.log('msg got ping let send pong');
          this.mediaChatApi.callMethod("pong")
          .subscribe (mediaChatApiEvent => {
            console.log("tried sending pong and here is the resp", mediaChatApiEvent);
          }, (error) => {
            console.log("tried sending pong and here is the error", error);
            this.errors.push (error)
          })
      }
      if (message.collection === 'stream-room-messages') {
        // success
        console.log("inside if condition success");
        this.chatMsg.push(message)
        //console.log("this.chatMsg",this.chatMsg);
        let arrivedJsonContent = message.fields.args[0].msg
        this.userInputMessage = JSON.parse(arrivedJsonContent)
        console.log("--arrived message",this.userInputMessage);
        if(this.userInputMessage.type==="Text"){
          this.addFabricCanvasFunc()
        }
        if(this.userInputMessage.type==="abhyasCanvas"){
          //console.log("--inputAreaCanvasDatalessJson",inputAreaCanvas.toDatalessJSON());
          let clientAreaCanvas=canvas.toDatalessJSON(['timeStamp'])
          //canvas.toDatalessJSON(['selectable', 'evented', 'id'])
          let receivedCanvasJSON = this.userInputMessage.content
          for(let i=0;i<receivedCanvasJSON.length;i++){
            clientAreaCanvas.objects.push(receivedCanvasJSON[i])
          }
          canvas.loadFromJSON(clientAreaCanvas,()=>{
            canvas.renderAll();
          })
        }
        this.userInputMessage=""
        console.log("--chatmsg--",this.chatMessage);
      }else{
        console.log("HARMLESS: inside else condition failure");
        //console.log("--chat messages to be--",message);
      }
    })
    this.mediaChatApi.connectToServer ()
      .subscribe (() => {
          this.mediaChatApi.keepAlive () // Ping Server
        },
        (error) => {
          this.errors.push (error)
        }
      )
    this.loginKCTokenFn()
    console.log("In mount of workbook wid")
    console.log("route wid is:", this.$route.params.wid)
    this.slot_id = this.$route.params.wid
    console.log("--",this.slot_id);
    await this.getAllParticipants(this.slot_id)
    this.slotPartId = this.candidatesList[0].slot_participant_id
    console.log("--slotPartId--",this.slotPartId)
    this.new_room_name=this.slotPartId
    this.getRoomsFn();
    //await this.startMeetFn();
    await this.viewMyChallengesFn()
    console.log("--chalID is",this.chalID);
    //await this.renderChalFn();

  },
  methods: {
    mouseUpEvent(){
      this.mouseDownValue = false
      console.log("drawing coordinates list", this.drawingCoordinatesList)
    },
    mouseDownEvent(){
      this.drawingCoordinatesList=[]
      this.mouseDownValue=true
    },
    getCoordinates(event){
      //console.log("mouse over")
      let mousePointer = inputAreaCanvas.getPointer(event.e)
      if(this.mouseDownValue && this.inputCanvasDrawingMode){
        this.x_cor = mousePointer.x
        this.y_cor = mousePointer.y
        const newDate = new Date();
        this.currentTime = newDate.getTime();
        //console.log("(x,y,ts) : ", this.x_cor, ",", this.y_cor, ",", this.currentTime)
        this.drawingCoordinatesList.push([this.x_cor, this.y_cor, this.currentTime])
      }
    },
    addTimeStampFN(opt){
      console.log("add time stamp function called", opt)
      console.log("path created", fabric.util.getPathSegmentsInfo(opt.path.path))
      //console.log("drawingCoordinatesList",this.drawingCoordinatesList);
      opt.path.timeStamp = this.drawingCoordinatesList
      opt.path.toObject = (function (toObject) {
                            return function () {
                                return fabric.util.object.extend(toObject.call(this), {
                                    "timeStamp": opt.path.timeStamp,
                                });
                            };
                        })(opt.path.toObject);
                        //console.log("--timestamp",opt.path.toObject);
    },
    formatInputField(){
      this.userChatMessage=""
      this.previewIinkMessage="";
      this.userInputMessage="";
      inputAreaCanvas.clear();
    },
    addFabricCanvasFunc(){
      //create a object of fabricjs-text: 
      this.chatMessage = new fabric.IText(this.userInputMessage.content, { left: 10, top: 30+this.canvasHeightLineLoc,fontSize:25 });
        
      //define the incremental top value of object of fabricjs-text
      let canvasElement = document.getElementById("canvas")
      console.log("height of canvas",canvasElement.style.height);
      let canvasElementHeight = parseInt(canvasElement.style.height)
      let newcanvasElementHeight = canvasElementHeight +30
      canvasElement.style.height = newcanvasElementHeight + 'px';
      console.log("--newcanvasElementHeight after increasing canvas height ",canvasElement.style.height);
      this.canvasHeightLineLoc=this.canvasHeightLineLoc+30
      //console.log("fabric.height",fabric.height);
        
      //add the text to canvas
      canvas.add(this.chatMessage)
    },
    /*inputAreaCanvasFunc(){
      inputAreaCanvas = new fabric.Canvas('inputCanvasArea',{
        isDrawingMode:this.inputCanvasDrawingMode
      });
    },*/
    ShowCanvas(){
      this.canvasOpen=true;
      this.textAreaOpen=false;
      this.editorType = "abhyasCanvas"
    },
    renderCanvasFn(){
      /*let svg = inputAreaCanvas.toSVG()
      console.log("--svg value--",svg);
      fabric.loadSVGFromString(svg,function(objects, options) {
        let obj = fabric.util.groupSVGElements(objects, options);
        canvas.add(obj).centerObject(obj).setActiveObject(obj);
        console.log("--obj value is--",obj);
      });*/
      //console.log("--inputAreaCanvasDatalessJson",inputAreaCanvas.toDatalessJSON());
      //let clientAreaCanvas=canvas.toDatalessJSON()
      let inputAreaCanvasJSON = inputAreaCanvas.toDatalessJSON(['timeStamp'])
      /*for(let i=0;i<inputAreaCanvasJSON.objects.length;i++){
        clientAreaCanvas.objects.push(inputAreaCanvasJSON.objects[i])
        //this.userChatMessage = inputAreaCanvasJSON.objects[i]
        //this.editorType = "abhyasCanvas"
        //this.sendMessage()
      }
      canvas.loadFromJSON(clientAreaCanvas,()=>{
        canvas.renderAll();
      })*/
      this.userChatMessage = inputAreaCanvasJSON.objects
      console.log("userhatmessage of canvas",this.userChatMessage);
      //this.editorType = "abhyasCanvas"
      //this.sendMessage()
      //inputAreaCanvas.clear();
     },
    ShowTextArea(){
      this.textAreaOpen=true;
      this.canvasOpen=false;
      this.editorType = "Text"
      this.editor=false;
    },
    toggleChalHeight(){
      let el = document.getElementById("chalArea");
      let height = el.offsetHeight;
      let newHeight = height;
      if(newHeight>100){
        newHeight=10
      }
      else if(newHeight<100){
        newHeight=100
      }
      else if(newHeight==100){
        newHeight=10
      }
      else{
        newHeight=height;
      }
      console.log("--newHeight",newHeight);
      el.style.height = newHeight + '%';
    },
    increaseChalHeight() {
      let el = document.getElementById("chalArea");
      let height = parseInt(el.style.height)
      let newHeight = height + 30
      if(newHeight>=100){
        newHeight=100
      }
      el.style.height = newHeight + '%';
      console.log("--newHeight of chal area after increasing",el.style.height);
    },
    decreaseChalHeight(){
      let el = document.getElementById("chalArea");
      let height = parseInt(el.style.height)
      if(height<30){
        let newHeight = height;
        el.style.height = newHeight + '%';
      }
      if(height>30){
        let newHeight = height -30
        el.style.height = newHeight + '%';
      }
      console.log("--newHeight of chal area after decreasing",el.style.height);
    },
    async getAllParticipants(slot_id){
        let getCandidateResp = await axios.get(this.work_api_Base_URL + "/v/me/get_all_participants/" + slot_id)
        console.log("all candidate resp---", getCandidateResp)
        this.candidatesList = getCandidateResp.data
        console.log("resp", this.candidatesList)
      },
    async renderChalFn(){
      let challengeResp = await axios.get(this.challenge_api_Base_URL + "/v/me/seek_chal/" + this.chalID)
      console.log("challenge content resp", challengeResp)
      // render the challenge from challeneg details
      let chalParts = challengeResp.data["parts"]
      let renderChallenge=""
      for(let idx=0;idx<chalParts.length;idx++){
        //console.log("loop of preview content, render value", renderChallenge)
        if(chalParts[idx]["chal_part_class"] == 'Text'){
          renderChallenge=renderChallenge + "<div id='preview_" + (idx+1).toString() + "'>" + chalParts[idx]["chal_part_text"] + "</div>"
        }
        else if(chalParts[idx]["chal_part_class"] == 'LaTeX'){
          let renderFormat = (katex.renderToString(chalParts[idx]["chal_part_text"]) + "<br>")
          renderChallenge=renderChallenge + "<div id='preview_" + (idx+1).toString() + "'>" + renderFormat + "</div>"
        }
        else if(chalParts[idx]["chal_part_class"] == 'Image'){
          let resp = await axios.get(chalParts[idx]["url"], { responseType: 'arraybuffer' })
          .then((response) => {
          let image = btoa(
              new Uint8Array(response.data)
              .reduce((data, byte) => data + String.fromCharCode(byte), '')
          );
          return `data:${response.headers['content-type'].toLowerCase()};base64,${image}`;
          });
          //console.log("img resp", resp)
          let ret = "<img style='height:300px;width:300px;' src='" + resp +"' title='" + chalParts[idx]["chal_part_text"] + "'/><br>"
          renderChallenge=renderChallenge + "<div id='preview_" + (idx+1).toString() + "'>" + ret + "</div>"
        }
      }
      document.getElementById("challenge_content").innerHTML = renderChallenge
    },
    async viewMyChallengesFn() {
        console.log("slot challenges");
        try {
            // TODO: How do we deal with changing API and UI versions. I need a well understood design and process.
            let myChallengeResp = await axios.get(this.challenge_api_Base_URL + "/v/me/my_chals");
            console.log("my challenges list resp", myChallengeResp);
            this.listMyChallenges = myChallengeResp.data.items
            this.chalID = myChallengeResp.data.items[0].id
            //this.viewMyChallenges = true;
        }
        catch (error) {
            console.log("error in resp of get my challenges is:", error.response);
            this.showRespMsg = true;
            this.respMsg = error.response.headers["message"];
        }
    },
    async startMeetFn(){
      await this.getUserToken()
      console.log("start meet function", this.userJwtToken)
      console.log("allCandidatesList in startConference", this.candidatesList)
      console.log("slotPartId",this.slotPartId);
      try {
          const domain = "8x8.vc";
          const options = {
            roomName: "vpaas-magic-cookie-804b1b1f50e9473e8f4be9b5ae12f646/"+this.slotPartId,
            //height: 500,
            parentNode: document.querySelector("#meet"),
            configOverwrite: {
              startWithVideoMuted: true,
              startWithAudioMuted: true,
              prejoinPageEnabled: false
            },
            jwt: this.userJwtToken,
          };

          this.mediaAudioApi = new window.JitsiMeetExternalAPI(domain, options);
          console.log("--------mediaAudioApi--------", this.mediaAudioApi)
        } catch (error) {
          console.error("Failed to load Jitsi API", error);
        }

    },
    createRoomFn(){
      console.log("create room");
      this.mediaChatApi.sendMessage({                                                                                                         
          "msg": "method",                                                                                                                   
          "method": "createChannel",                                                                                                         
          "id": '' + new Date ().getTime (),                                                                                                                     
          "params": [                                                                                                                        
              this.new_room_name,                                                                                                            
              ["soma.pujari","rahul.sharma"],                                                                                                                            
              false                                                                                                                          
          ]                                                                                                                                  
      }) 
      //this.createRoomWindow=false;
      this.getRoomsFn();
    },
    connectRoom() {
      console.log("connect to room",this.roomId)
      let resp = this.mediaChatApi.sendMessage ({
        "msg": "sub",
        "id": '' + new Date ().getTime (),
        "name": "stream-room-messages",
        "params": [
          this.roomId,
          false
        ]
      })
      console.log("--notify that userlogin",resp);
      this.editorType ="sysEvent"
      this.userChatMessage = "user is connected to the room"
      this.sendMessage()
      console.log("userInputMessage",this.userInputMessage);
    },
    leaveRoomFn(){
      this.mediaAudioApi.executeCommand('hangup');
      console.log("UI base url",this.UI_baseURL);
      this.editorType ="sysEvent"
      this.userInputMessage = "user leaved the meeting"
      this.sendMessage();
      window.location.href = this.UI_baseURL + "/";
      //this.userInputMessage = ""
    },
    /*leaveRoomFn() {
      console.log("leave the room",this.roomId)
      let resp = this.mediaChatApi.sendMessage ({
        "msg": "method",
        "id": '11',
        "method": "leaveRoom",
        "params": [
          this.roomId,
        ]
      })
      console.log("resp of leaving the room-",resp);
    },*/
    getRoomsFn() {
      console.log("inside getroom function");
      //console.log("functions in mediaChatApi are", this.mediaChatApi)
      this.mediaChatApi.callMethod ("rooms/get", [{"$date":1677130540}])
      .subscribe (mediaChatApiEvent => {
        console.log("inside subscribe of getroom function");
          if (mediaChatApiEvent.msg === 'result') {
              // success
              console.log("resp from get rooms:", mediaChatApiEvent);
              this.roomsInfo = mediaChatApiEvent.result
              console.log("--roomInfo",this.roomsInfo);
              for(let idx=0;idx<this.roomsInfo.length;idx++){
                if(this.roomsInfo[idx]["name"]===this.slotPartId){
                  this.roomId=this.roomsInfo[idx]["_id"]
                  console.log("--slotPartId---",this.roomId);
                  break;
                }
              }
              console.log("room id's",this.roomId);
              if(this.roomId===""){
                this.createRoomFn();
              }
              else{
                this.connectRoom();
              }
          }
          }, (error) => {
          this.errors.push (error)
          })
    },
    sendMessage() {
      if(this.editorType == "abhyasCanvas"){
        this.renderCanvasFn()
      }
      let chatContent = {
        type: this.editorType,
        content: this.userChatMessage,
        file_location: "",
        render_spec:"",
      }
      this.userInputMessage= JSON.stringify(chatContent);
      console.log("enterblank sendMsg",this.userInputMessage);
      this.mediaChatApi.sendMessage ({
      msg: "method",
      method: "sendMessage",
      id: '' + new Date ().getTime (),
      params: [
          {
          "_id": '' + new Date ().getTime (),
          "rid":  this.roomId,
          "msg":  this.userInputMessage
          }
      ]
      })
      this.userInputMessage = "";
      this.userChatMessage="";
      inputAreaCanvas.clear();
      console.log("existing the send msg function",this.chatMessage);
    },
    loginKCTokenFn(){
      if (!this.loggedIn) {
      let login_data={
          serviceName: "keycloak",
          accessToken: this.$keycloak["token"],
          idToken: this.$keycloak["idToken"],
          expiresIn: this.$keycloak["tokenParsed"]["exp"]
      }
      console.log("login data", login_data);
      this.mediaChatApi.callMethod("login", login_data)
          .subscribe (mediaChatApiEvent => {
          if (mediaChatApiEvent.msg === 'result') {
              // success
              this.messages.push (mediaChatApiEvent.msg)
              this.loggedIn = true
          }
          }, (error) => {
          this.errors.push (error)
        })
      }
    },
    preview_content(){
    this.previewFormula = (katex.renderToString(this.userInputMessage) + "<br>")
    if(this.userInputMessage===''){
      this.previewFormula = ''
    }
    document.getElementById('katext_preview').innerHTML = this.previewFormula
    },
    send_content(){
      let emitData={}
      //emitData["actualContent"] = this.formulaValue
      emitData["type"] = "KaTeX"
      emitData["content"] = (katex.renderToString(this.formulaValue) + "<br>")
      if(this.formulaValue===''){
        emitData["HTML"] = ''
      }
      this.userInputMessage = ""
      document.getElementById('katext_preview').innerHTML = '<h1 style="text-align:center;"> Preview </h1>'
      this.$emit('clicked', emitData)
    },
    async getUserToken(){
      try {
        // TODO: How do we deal with changing API and UI versions. I need a well understood design and process.
        let resp = await axios.get(this.work_api_Base_URL + "/v/me/get_media_token/" + this.slotPartId);
        console.log("jwt resp", resp);
        this.userJwtToken = resp.data;
        //this.viewMyChallenges = true;
      }
      catch (error) {
          console.log("error in resp of jwt resp:", error.response);
          this.showRespMsg = true;
          this.respMsg = error.response.headers["message"];
      }
    }
  },
}
</script>
<style>
.column {
float: left;
}

/* Clear floats after the columns */
.row:after {
content: "";
display: table;
clear: both;
}
div.scroll {
    margin:4px, 4px;
    padding:4px;
    height: 420px;
    overflow-x: auto;
    overflow-y: auto;
    text-align:justify;
}
div.scrollChal{
    margin:4px, 4px;
    padding:4px;
    height: 100px;
    overflow-x: auto;
    overflow-y: auto;
    text-align:justify;
}
#canvas-wrapper {
  position: absolute;
  top: 50px;
  left: 100px;
}
</style>